<template>
  <div class="summary-chemical-inventory">
    <component
      :is="modal.current"
      :isOpen="modal.open"
      :informativeText="informativeText"
      @close="modal.open = false"
      @submit-request="submitClassificationRequest"
    />

    <b-modal
      id="limitedDailySearches"
      size="md"
      header-bg-variant="danger"
      header-text-variant="light"
      title="Atingido o limite de pesquisas diário"
      ref="limitedDailySearches"
      hide-footer
    >
      <p v-if="dailySearches.expireAt">
        O limite do plano gratuito de adições em 24 horas foi atingido. Aguarde
        até
        {{
          dailySearches.expireAt
            | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm')
        }}
        , para ter liberar novas adições ou tenha acesso aos recursos completos
        adquirindo um dos planos
        <a href="/planos"> Meus Planos </a>
      </p>
    </b-modal>

    <b-modal
      id="rmconfirmation"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Confirmar ação?"
      ref="rmconfirmation"
      hide-footer
    >
      <p>
        Deseja confirmar a ação? Uma vez confirmada a ação não pode ser desfeita
      </p>
      <b-button variant="danger" @click="remove(removeItem)" class="mr-3"
        >Remover</b-button
      >
      <b-button @click="$bvModal.hide('rmconfirmation')">Cancelar</b-button>
    </b-modal>

    <button
      v-if="hasSelected"
      class="btn btn-danger"
      @click="$refs.rmconfirmation.show()"
    >
      <i class="fa fa-trash"></i> Apagar
    </button>

    <table class="text-center table b-table table-bordered custom-table">
      <thead class='headerTable'>
        <tr></tr>
        <tr>
          <th width="5%">Selecionar</th>
          <th width="25%">CAS: Agente</th>
          <th width="15%">% do agente no produto</th>
          <th width="10%">ACGIH</th>
          <th width="10%">NR 15</th>
          <th width="10%">LINACH</th>
          <th width="10%">ESOCIAL - Tabela 24</th>
          <th width="10%">DECRETO 3048/99 - Anexo IV</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <b-form-checkbox :checked="selectedAll" @change="selectAll">
              Todos
            </b-form-checkbox>
          </td>
          <td>
            <vue-bootstrap-typeahead
              class="mb-2 mb-sm-2 chemical-inventory"
              v-model="agent"
              :maxMatches="maxMatchesSelect"
              :serializer="(item) => item.searchMatched"
              :data="agents"
              sort-variant="length"
              @hit="selectByAgente($event)"
              @input="clear"
              placeholder="CAS: Agente"
              ref="agenBaseAutocomplete"
            />
            <img v-if="loading" src="/img/loading.gif" />
          </td>

          <td colspan="6">
            <div class="legend">
              <span>Legenda: </span>
              <span>
                <i class="fa fa-check"></i> consta,
              </span>
              <span>
                <i class="fa fa-times"></i> não consta,
              </span>
              <span>
                <i class="fa fa-history"></i> em classificação.
              </span>
            </div>
          </td>
        </tr>

        <tr v-for="item in summary" :key="item.chemicalInventoryId">
          <template v-for="field in model.tableFields">
            <td :key="`field_${field.key}`">
              <template v-if="field.key === 'selectedToDelete'">
                <b-form-checkbox
                  @change="select(item.chemicalInventoryId)"
                  :checked="checked(item.chemicalInventoryId)"
                ></b-form-checkbox>
              </template>
              <template v-else-if="field.key === 'actions'">
                <b-button
                  variant="primary"
                  class="mb-1 mr-1 button-remove"
                  @click="removeConfirmation(item.chemicalInventoryId)"
                >
                  <i class="fas fa-trash-alt"></i>
                </b-button>
              </template>
              <template v-else-if="field.key === 'percentage'">
                <base-input-text
                  v-model="item[field.key]"
                  :min="0"
                  :max="100"
                  placeholder="Porcentagem"
                  type="number"
                  block
                  @saveBlur='handleSubmitPercentage(item)'
                />
              </template>
              <template v-else>
                <template v-if="!model.getExist(field.key, item)">{{
                  model.getValue(field.key, item)
                }}</template>
                <template v-else>
                  <i :class="`fas ${model.getExist(field.key, item)}`" />
                </template>
              </template>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ChemicalInventorySummary from '@/models/ChemicalInventorySummary'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import ModalAgentNotFound from '@/components/LegalSearch/Modal/AgentNotFound'
import ModalRequestAgent from '@/components/LegalSearch/Modal/RequestAgent'
import ModalRequestSent from '@/components/LegalSearch/Modal/RequestSent'
import _ from 'underscore'
import { mapActions, mapGetters } from 'vuex'

export default {
  async mounted() {
    this.$refs.agenBaseAutocomplete.$el.querySelector('input').addEventListener('keyup', (e) => {
      const searchedByCas = new RegExp(/\b[1-9]{1}[0-9]{1,10}-\d{2}-\d\b|\bCAS-[1-9]{1}[0-9]{1,10}-\d{2}-\d\b/).test(this.$refs.agenBaseAutocomplete.inputValue);
      if(searchedByCas) {
        this.$refs.agenBaseAutocomplete.inputValue = this.$refs.agenBaseAutocomplete.inputValue.replace(' ', '')
      }
      this.agent = this.$refs.agenBaseAutocomplete.inputValue
    })
  },
  name: 'SummaryChemicalInventory',
  props: {
    productId: {
      type: String,
      require: true,
    },
  },
  created() {
    this.load()
  },
  components: {
    VueBootstrapTypeahead,
    ModalAgentNotFound,
    ModalRequestAgent,
    ModalRequestSent
  },
  data() {
    return {
      model: ChemicalInventorySummary,
      loading: false,
      hidden: true,
      disabled: true,
      removeAll: '',
      removeItems: [],
      maxMatchesSelect: 100,
      agent: '',
      agents: [],
      removeItem: '',
      hitted: false,
      inventoryMessage: '',
      inventory: [],
      itemsToRemove: [],
      message: '',
      options: [],
      alertvariant: 'success',
      dailySearches: {},
      informativeText: '',
      casSearch: '',
      modal: {
        current: "ModalRequestAgent",
        open: false
      }
    }
  },
  methods: {
    ...mapActions('chemicalInventory', {
      handleUpdate: 'handleUpdate'
    }),
    ...mapActions('classificationRequests', {
      createClassificationRequest: 'handleCreateClassificationRequest',
    }),
    async add() {
      try {
        this.disabled = true

        const result = await this.$store.dispatch('chemicalInventory/add', {
          product: this.productId,
          agent: this.selectedAgent._id,
          name: this.selectedAgent.searchMatched,
          acgih_data: {},
        })

        await this.load()
        this.selectedAgent = undefined
        this.$refs.agenBaseAutocomplete.inputValue = ''

        if (result.message === 'Reached the number of daily searches') {
          this.dailySearches = result.dailySearches
          this.$refs.limitedDailySearches.show()
        }

      } catch (err) {
        console.log(err)
      }
    },
    clear() {
      if (this.$refs.agenBaseAutocomplete.inputValue === '') {
        this.selectedAgent = undefined
        this.disabled = true
      }
    },
    checked(id) {
      return this.itemsToRemove.indexOf(id) >= 0
    },
    async load() {
      await this.$store.dispatch('chemicalInventory/load', this.productId)
    },
    async remove() {
      await this.$store.dispatch('chemicalInventory/remove', this.itemsToRemove)
      this.itemsToRemove = []
      await this.load()
      this.$refs.rmconfirmation.hide()
    },
    removeConfirmation(i) {
      this.itemsToRemove.push(i)
      this.$refs.rmconfirmation.show()
    },
    select(id) {
      let index = this.itemsToRemove.indexOf(id)
      if (index >= 0) {
        this.itemsToRemove.splice(index, 1)
      } else {
        this.itemsToRemove.push(id)
      }
    },
    async selectByAgente($event) {
      let agent = $event
      this.hitted = true

      this.options = this.agents.filter((item) => {
        if (agent.searchMatched === item.searchMatched) return item
      })

      this.selectedAgent = $event
      this.agent = ''
      this.disabled = false
      await this.add()
    },
    selectAll() {
      if (this.hitted) {
        this.hitted = false
        return
      }
      if (this.selectedAll) {
        this.itemsToRemove = []
      } else {
        this.itemsToRemove = this.summary.map(
          (item) => item.chemicalInventoryId
        )
      }
    },
    async handleSubmitPercentage(item){
      await this.handleUpdate(item)

      this.$bvToast.toast('Tabela atualizada com sucesso', {
        title: 'Atualizado',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });
    },
    async submitClassificationRequest(){
      this.modal.open = false
      this.classificationRequest.cas = this.casSearch

      const { status, data } = await this.$api.post(`agents/addChemicalAgent`, {cas: this.casSearch})

      if(status === 200 || status === 201){
        const { chemicalAgent } = data

        const chemicalAgentAdd = {
          _id: chemicalAgent._id,
          searchMatched: `${chemicalAgent.cas}:`
        }
        this.selectedAgent = chemicalAgentAdd
      }
      await this.add()

      const response = await this.createClassificationRequest()

      if(response.message === 'Classification request created successfully'){
        this.modal.current = 'ModalRequestSent'
        this.modal.open = true
        this.informativeText = `Informamos que os agentes que ainda não entraram em nossa base de dados ou que estão em processo de classificação não serão considerados para efeitos de análise no relatório`
        this.$refs.agenBaseAutocomplete.inputValue = ''
      }
    },
  },
  computed: {
    ...mapGetters('classificationRequests', {
      classificationRequest: 'getClassificationRequest',
    }),
    selectedAgent: {
      get: function () {
        return this.$store.getters['chemicalInventory/selectedAgent']
      },
      set: function (agent) {
        this.$store.dispatch('chemicalInventory/select', agent)
      },
    },
    hasSelected() {
      return this.itemsToRemove.length > 0
    },
    selectedAll: {
      get: function () {
        return this.itemsToRemove.length === this.summary.length
      },
      set: function () {},
    },
    summary() {
      return this.$store.getters['chemicalInventory/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
  watch: {
    agent: _.debounce(async function (newQuery) {
      if (!newQuery || newQuery.length < 3) return
      this.loading = true
      this.casSearch = newQuery
      const response = (await this.$api.get(`agents/search?agent=${newQuery}`)).data

      this.agents = response.chemicalAgents

      if(response.chemicalAgents.length <= 0){
        this.modal.current = response.searchedByCas ? 'ModalRequestAgent' : 'ModalAgentNotFound'
        this.informativeText = `Este agente não estava em nossa base de dados, porém classificaremos para você o mais rápido possível. As informações relativas a este agente, como exemplo do seu nome e sinônimo, serão atualizadas automaticamente. Por hora somente o CAS deste agente será necessário. Você gostaria de adicionar este agente com estas condições?`
        this.modal.open = true
      }
      this.loading = false
    }, 1500),
  },

}
</script>

<style lang="scss">
.headerTable{
  vertical-align: middle;
  background: #94D5F3;
  padding: 10px;
  position: sticky;
  top: 83px;
  z-index: 10;
}
.summary-chemical-inventory{
  margin-bottom: 250px;
}
button.button-inventory:not(:disabled) {
  background-color: green;
}
.chemical-inventory > .form-control {
  background-image: url('/img/loading.gif');
}
.legend {
  display: flex;
  gap: 16px;

  margin-top: 8px;

  span:nth-child(1) {
    font-weight: 700;
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 18px;
  }
}
</style>
