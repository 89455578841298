<template>
  <base-modal class="options-modal" :isOpened="isOpen" @close="closeModal">
    <template v-slot:header> SELECIONAR {{ types[form.type] }} </template>
    <template v-slot:content>
      <base-input-text
        v-if="options && options.length > 10"
        class="space"
        v-model="search"
        placeholder="Digite o nome"
        :label="`PESQUISAR ${types[form.type]}`"
        type="text"
        @input="filterByName()"
      />

      <base-input-radio
        v-if="results && results.length > 0"
        v-model="selected"
        :options="results"
        _key="_id"
        _value="name"
        direction="column"
      />
      <span v-else>Nenhum resultado encontrado</span>
    </template>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Modal',
  props: {
    isOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      search: '',
      results: [],
      selected: '',
      error: '',
      type: '',
      name: '',
      body: {},
      parent: undefined,
      parentOptions: [],
      types: {
        companies: 'EMPRESA',
        branches: 'UNIDADE',
        departments: 'SETOR',
        roles: 'CARGO',
        products: 'PRODUTO',
        termsOfUse: 'CONDIÇÕES DE USO',
      },
    }
  },
  computed: {
    ...mapGetters('hierarchy', {
      companies: 'companies',
      branches: 'branches',
      departments: 'departments',
      roles: 'roles',
      products: 'products',
      form: 'form',
      termsOfUse: 'termsOfUse',
    }),
    options() {
      return this[this.form.type]
    },
  },
  async created() {
    this.results = this.options
  },
  methods: {
    filterByName(){
      this.results = this.options.filter((item) =>  item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 )
    },
    closeModal() {
      this.$emit('close')
    },
  },
  watch: {
    selected() {
      if (this.selected) {
        this.$emit(
          'selected',
          this.options.find((item) => item._id === this.selected)
        )
        this.selected = ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.space{
 padding: 0 30px 10px 0;
}
.options-modal {
  /deep/ header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/ main {
    margin: 16px;
  }
}
</style>
