<template>
  <div class="complete-selects">
    <base-autocomplete
      _value="name"
      class="input-item width-input"
      label="Empresa:"
      placeholder="Digite ou selecione uma opção"
      ref="company"
      type="company"
      :items="companies"
      v-model="company"
      @add="(payload) => $emit('add', payload)"
      @change="$emit('update', 'company')"
      @edit="(payload) => $emit('edit', payload)"
      @search="$emit('search', 'companies')"
    />

    <base-autocomplete
      _value="name"
      class="input-item width-input"
      label="Unidade:"
      placeholder="Digite ou selecione uma opção"
      ref="branch"
      type="branch"
      :disabled="!company"
      :items="branches"
      :parent="company"
      v-model="branch"
      @add="(payload) => $emit('add', payload)"
      @change="$emit('update', 'branch')"
      @edit="(payload) => $emit('edit', payload)"
      @search="$emit('search', 'branches')"
    />

    <base-autocomplete
      _value="name"
      class="input-item width-input"
      label="Setor:"
      placeholder="Digite ou selecione uma opção"
      ref="department"
      type="department"
      :disabled="!branch"
      :items="departments"
      :parent="branch"
      v-model="department"
      @add="(payload) => $emit('add', payload)"
      @change="$emit('update', 'department')"
      @edit="(payload) => $emit('edit', payload)"
      @search="$emit('search', 'departments')"
    />

    <base-autocomplete
      _value="name"
      class="input-item width-input"
      label="Cargo:"
      placeholder="Digite ou selecione uma opção"
      ref="role"
      type="role"
      v-model="role"
      :disabled="!department"
      :parent="department"
      :items="roles"
      @add="(payload) => $emit('add', payload)"
      @change="$emit('update', 'role')"
      @edit="(payload) => $emit('edit', payload)"
      @search="$emit('search', 'roles')"
    />

    <base-autocomplete-hierarchy
      _value="name"
      class="input-item width-input"
      label="Produto:"
      ref="product"
      placeholder="Digite ou selecione uma opção"
      type="product"
      :disabled="!role"
      :items="products"
      :parent="role"
      v-model="product"
      @add="(payload) => $emit('add', payload)"
      @change="$emit('update', 'product')"
      @edit="(payload) => $emit('edit', payload)"
      @search="$emit('search', 'products')"
    />

    <base-button :danger="!!(product && !termsOfUse)" :class="`${(product && !termsOfUse) ? 'copy-button width-input warning-conditions-of-use-button': 'copy-button width-input'}`" :disabled="!product" @click="add()">
      Condições de uso
    </base-button>
  </div>
</template>

<script>
export default {
  name: 'CompleteChemicalInventory',
  props: {
    companies: { type: Array, default: () => [] },
    branches: { type: Array, default: () => [] },
    departments: { type: Array, default: () => [] },
    roles: { type: Array, default: () => [] },
    products: { type: Array, default: () => [] },
    termsOfUse: { type: String, default: () => null },
  },
  data() {
    return {
      company: '',
      branch: '',
      department: '',
      role: '',
      product: '',
      conditionOfUse: '',
    }
  },
  created() {
    this.$parent.$on('fillInTheProductFieldWhenImporting', this.setFillInTheProductFieldWhenImporting);
  },
  methods: {
    setFillInTheProductFieldWhenImporting(product) {
      this.$emit('setProductImport', product);
    },
    add() {
      this.$emit('set-conditionOfUse', {
        name: 'Condições de Uso',
        type: 'termsOfUse',
        parent: this.product,
      })
    },
  },
  watch: {
    company() {
      this.$emit('get-options', { type: 'companies' })
      this.branch = ''
      this.$emit('get-children', ['company', this.company])
      this.$emit('set-company', this.company)
      if(!this.company){
        this.branch = '';
        this.$emit('set-branch', '')
      }
    },
    branch() {
      if (this.company) {
        this.$emit('get-options', { type: 'branches', parent: this.company })
        this.$emit('get-children', ['branch', this.branch])
        this.$emit('set-branch', this.branch)
      }
      if(!this.branch){
        this.department = ''
        this.$emit('set-department', '')
      }
    },
    department() {
      if (this.branch) {
        this.$emit('get-options', { type: 'departments', parent: this.branch })
        this.role = ''
        this.$emit('get-children', ['role', this.role])
        this.$emit('set-department', this.department)
      }
      if(!this.department){
        this.role = '';
        this.$emit('set-role', '')
      }
    },
    role() {
      if (this.department) {
        this.$emit('get-options', { type: 'roles', parent: this.department })
        this.product = ''
        this.$emit('get-children', ['role', this.role])
        this.$emit('set-role', this.role)
      }
      if(!this.role){
        this.product = '';
        this.$emit('set-product', '')
      }
    },
    product() {
      if (this.role) {
        this.$emit('get-options', { type: 'products', parent: this.role })
        this.$emit('get-children', ['termsOfUse', this.product])
        this.$emit('set-product', this.product)
      }
      if(!this.product){
        this.conditionOfUse = '';
        this.$emit('set-conditionOfUse', '')
      }
    },
    conditionOfUse() {
      if (this.product)
        this.$emit('get-options', { type: 'termsOfUse', parent: this.product })

        this.$emit('set-conditionOfUse', this.conditionOfUse)
    },
  },
}
</script>

<style lang="scss">
.warning-conditions-of-use-button {
  -webkit-animation: warning-conditions-of-use-button 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: warning-conditions-of-use-button 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: warning-conditions-of-use-button 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: warning-conditions-of-use-button 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  background-color: var(--blue);
  box-shadow: 0 0 0 0 var(--blue);

  &:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
  }
}
@-webkit-keyframes warning-conditions-of-use-button {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes warning-conditions-of-use-button {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes warning-conditions-of-use-button {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@keyframes warning-conditions-of-use-button {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

.width-input {
  width: 10%;
  min-width: 250px;
  margin: 10px 16px 0 0;
}
.complete-selects {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  /*grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  flex-wrap: wrap;*/
}
</style>
