<template>
  <div maxWidth="max-width: 700px;" maxHeight='max-height: 480px;'>
  <b-modal centered ref='show-log' size='xl' scrollable hide-footer title='Selecione a ventilação identificada no Local'>
    <div class='modalVentilacao'  v-for="(item, i) in optionsVentilationIdentified" :key="i"    >
      <figure @click='setKey(item)' class='img-caption' >
        <img  width='100' :alt='item.value' :title='item.value' height='100' v-bind:src="require('../../../assets/images/'+item.img+'')"/>

        <figcaption>
          <p class='caption' >{{item.value}}</p>
        </figcaption>
      </figure>
    </div>
  </b-modal>

  <base-modal  class="hierachy-modal" :isOpened="isOpen" @close="closeModal">
    <template v-slot:header>
      <template v-if="form._id"> ATUALIZAR </template>
      <template v-else> ADICIONAR </template>{{ types[form.type] }}
    </template>

    <template v-slot:content>
      <base-alert danger v-if="error">{{ error }}</base-alert>

      <div class="inputs">
        <base-input-text
          v-if="form.type !== 'termsOfUse'"
          v-model="form.name"
          placeholder="Digite o nome"
          label="Nome:"
          type="text"
        />
        <template v-if="form.type === 'product'">
          <base-text-area
            v-model="body.description"
            class="custom-textarea"
            placeholder="Descreva aqui como é utilizado este produto. Informações sobre como o trabalhador faz uso deste produto, se ele dilui/mistura/dissolve, se o produto está em forma líquida ou sólida são boas informações para que possamos te ajudar a reconhecer seus riscos."
            label="Descrição:"
            type="text"
          />

          <a class="file-uploaded" v-if="form.body && form.body.file" :href="body.file || ''" target="_blank">
            <i @click="removeFile(index)" class="fa fa-file"/>
            Arquivo anexado
          </a>

          <base-input-file
            v-model="files"
            label="Clique aqui para anexar a FISPQ deste produto"
            name="product-file"
            block
            :max-size="MAX_SIZE_IN_BYTES"
          />
        </template>

        <template v-else-if="form.type === 'role'">
          <base-input-text
            v-model="body.workload"
            placeholder="Carga horária diária (em horas)"
            label="Carga horária diária (em horas):"
            type="number"
          />

          <base-text-area
            v-model="body.description"
            class="custom-textarea"
            placeholder="Descreva aqui como é este cargo de trabalho. Informações sobre o que o trabalhador faz, como ele executa sua atividade, e quais equipamentos  ele utiliza são boas informações para que possamos te ajudar a reconhecer seus riscos."
            label="Descrição do cargo:"
            type="text"
          />
        </template>

        <template v-if="form.type === 'termsOfUse'">
          <base-input-text
            idTooltip="temperature"
            textTooltip="Informe no campo abaixo qual a temperatura em que o produto está ou em que ele está sendo utilizado no processo produtivo"
            v-model="body.temperature"
            placeholder="Digite a temperatura"
            label="Temperatura C°:"
            icon="fa fa-question-circle"
            type="number"
          />

          <div class="ventilationIdentified">
            <label class="label">Ventilação identificada no Local:</label>
            <div
                @click="show()"
                class="btn">
                {{
                  body.ventilationIdentified ?
                  optionsVentilationIdentified.find((x) => x.key === Number(body.ventilationIdentified)).value :
                  'Clique aqui para informar qual a ventilação relacionada a este produto'
                }}
            </div>
          </div>

          <base-input-select
            idTooltip="quantityUsed"
            textTooltip="Selecione a quantidade diária utilizada deste produto. Esta informação se refere a quantidade diária que o
                          trabalhador usa durante sua jornada de trabalho, ou seja, durante o dia quando ele executa a atividade ele usa quantos
                          quilos ou litros desse produto?"
            icon="fa fa-question-circle"
            v-model="body.quantityUsed"
            :options="optionsQuantityUsed"
            label="Quantidade diária utilizada deste produto:"
            placeholder="Quantidade diária utilizada deste produto"
            _key="key"
            _value="value"
          />

          <base-input-select
            idTooltip="frequencyUse"
            textTooltip="Selecione a frequência utilizada deste produto. Esta informação está relacionada a quantidade aproximada de quantas vezes o trabalhador utiliza este produto por dia. Caso o produto seja utilizado em dias alternados ou com frequência reduzida, selecione a opção mais próxima dentre as ofertadas abaixo. Dica para facilitar a seleção da opção: pense em quantidade de uso por dia, senão pense em quantidade de usos por semana ou mês."
            icon="fa fa-question-circle"
            v-model="body.frequencyUse"
            :options="optionsFrequencyUse"
            label="Frequência diária de utilização deste produto:"
            placeholder="Frequência diária de utilização deste produto"
            _key="key"
            _value="value"
          />

          <base-input-select
            idTooltip="exposureTime"
            textTooltip="Selecione o tempo total diário estimado em que este trabalhador fica exposto a este produto.
                          Esta informação está relacionada com o tempo aproximado de quantas horas ou minutos o trabalhador utiliza este produto por dia.
                          Caso o produto seja utilizado em dias alternados ou com frequência reduzida, selecione a opção mais próxima dentre as ofertadas abaixo.
                          Dica para facilitar a seleção da opção: pense em quantidade de uso por dia, senão pense em quantidade de usos por semana ou mês."
            icon="fa fa-question-circle"
            v-model="body.exposureTime"
            :options="optionsExposureTime"
            label="Tempo de exposição (do trabalhador com o uso do produto):"
            placeholder='Selecione o tempo de exposição que o trabalhador fica ao utilizar o produto.'
            _key="key"
            _value="value"
            />

          <base-input-text
            idTooltip="exposedWorkers"
            textTooltip="Digite a quantidade de trabalhadores expostos"
            icon="fa fa-question-circle"
            v-model="body.exposedWorkers"
            label="Quantidade de trabalhadores expostos:"
            placeholder="Digite a quantidade de trabalhadores expostos"
            type="number"
          />

          <base-input-select
            idTooltip="physicalStateProduct"
            textTooltip="Selecione o estado físico do produto"
            icon="fa fa-question-circle"
            v-model="body.physicalStateProduct"
            :options="physicalProductStates"
            label="Selecione o estado físico do produto:"
            placeholder="Digite o estado físico do produto"
            _key="key"
            _value="value"
          />

          <base-text-area
            v-model="body.description"
            class="custom-textarea"
            placeholder="Descreva aqui como é utilizado este produto. Informações sobre como o trabalhador faz uso deste produto, se ele dilui/mistura/dissolve, se o produto está em forma líquida ou sólida são boas informações para que possamos te ajudar a reconhecer seus riscos."
            label="Descrição:"
            type="text"
          />
        </template>

        <base-text-area
          v-else-if="form.type === 'department'"
          v-model="body.description"
          class="custom-textarea"
          placeholder="Descreva aqui como é este setor de trabalho. Informações como ventilação, temperatura aproximada, tamanho do local são boas informações para que possamos te ajudar a reconhecer seus riscos."
          label="Descrição do setor:"
          type="text"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <base-button @click="closeModal" danger>Cancelar</base-button>

        <base-button v-show="form._id && form.type !== 'termsOfUse'" @click="alertRemove">
          Excluir {{ types[form.type] && types[form.type].toLowerCase() }}
        </base-button>

        <base-button success v-if="form._id" @click="update">
          Atualizar {{ types[form.type] && types[form.type].toLowerCase() }}
        </base-button>

        <base-button v-else @click="submitHierarchy" success>
          Adicionar {{ types[form.type] && types[form.type].toLowerCase() }}
        </base-button>
      </div>
      <base-alert danger v-if="deleteAlert">
        Confirmar exclusão? Essa ação não pode ser desfeita
        <base-button @click="alertRemove"> Não </base-button>
        <base-button @click="remove" sucess> Sim </base-button>
      </base-alert>
    </template>
  </base-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseInputSelect from '../../../components/Base/BaseInputSelect'
export default {
  name: 'Modal',
  props: {
    isOpen: { type: Boolean, default: false },
  },
  components() {
    BaseInputSelect
  },
  data() {
    return {
      MAX_SIZE_IN_BYTES: 5242880,
      error: '',
      type: '',
      name: '',
      body: {},
      parent: undefined,
      parentOptions: [],
      deleteAlert: false,
      types: {
        company: 'EMPRESA',
        branch: 'UNIDADE',
        department: 'SETOR',
        role: 'CARGO',
        product: 'PRODUTO',
        termsOfUse: 'CONDIÇOES DE USO',
      },
      optionsVentilationIdentified: [
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 1,
          value: 'Ambiente aberto com ventilação natural',
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 2,
          value: 'Ventilação geral com menos de 3 a 6 trocas de ar por hora',
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 3,
          value:
            'Boa ventilação geral com menos de 6 a 12 trocas de ar por hora',
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 4,
          value:
            'Boa ventilação geral com exaustão local nos pontos de emissão',
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 5,
          value: 'Captura no ponto de emissão com contenção',
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 6,
          value: 'Enclausuramento do agente (uma barreira de proteção)',
        },
        {
          model: 'ventilationIdentified',
          img: 'ho-facil-blue.png',
          key: 7,
          value:'Enclausuramento primário e secundário (duas barreiras de proteção)',
        },
      ],
      optionsQuantityUsed: [
        {
          key: 1,
          value: 'Menos que 1 kg ou L',
        },
        {
          key: 2,
          value: 'Entre 1 a 10 kg ou L',
        },
        {
          key: 3,
          value: 'Entre 10 a 50kg ou L',
        },
        {
          key: 4,
          value: 'Entre 50 a 100 Kg ou L',
        },
        {
          key: 5,
          value: 'Entre 100 a 1000 kg ou L',
        },
        {
          key: 6,
          value: 'Mais que 1000 Kg ou L',
        },
      ],
      optionsFrequencyUse: [
        {
          key: 1,
          value: 'Mais que 2x por dia',
        },
        {
          key: 2,
          value: 'De 1 a 2x por dia',
        },
        {
          key: 3,
          value: 'Mais que 2x por semana',
        },
        {
          key: 4,
          value: 'De 1 a 2x por semana',
        },
        {
          key: 5,
          value: 'Mais que 2x por mês',
        },
        {
          key: 6,
          value: 'Menos que 1 a 2x por mês',
        },
      ],
      optionsExposureTime: [
        {
          key: 1,
          value: 'Maior que 4h por dia',
        },
        {
          key: 2,
          value: 'Entre 2 a 4h por dia',
        },
        {
          key: 3,
          value: 'Entre 1 a 2h por dia',
        },
        {
          key: 4,
          value: 'Entre 30 a 60 min por dia',
        },
        {
          key: 5,
          value: 'Entre 10 a 30 min por dia',
        },
        {
          key: 6,
          value: 'Menor que 10 min por dia',
        },
      ],
      files: [],
      physicalProductStates: [
        {
          key: '1',
          value: 'Sólido',
        },
        {
          key: '2',
          value: 'Líquido',
        },
        /*{
          key: 3,
          value: 'Gasoso',
        },*/
      ],
    }
  },
  computed: {
    ...mapGetters('hierarchy', {
      companies: 'companies',
      branches: 'branches',
      departments: 'departments',
      roles: 'roles',
      products: 'products',
      form: 'form',
    }),
  },
  methods: {
    ...mapActions('hierarchy', {
      addHierarchyLevel: 'addHierarchyLevel',
      getParents: 'getParents',
      updateHierarchy: 'updateHierarchy',
      setFormData: 'setFormData',
    }),
    setKey(item){
      switch (item.model) {
        case 'ventilationIdentified' :
          this.body.ventilationIdentified = item.key;
          break;
      }
      this.close()
    },
    show() {
      this.$refs['show-log'].show()
    },

    close() {
      this.$refs['show-log'].hide()
    },
    async updateParents() {
      this.getParents(this.type)
    },
    _fieldsRequiredError(item) {
      if (!item.type || !item.name) return true
      if (item.type === 'department' && !item.hasOwnProperty('parent')) return false
      if (item.type != 'company' && !item.parent) return true
      if (item.type === 'role' && !this.body.description) return true
      if (item.type === 'product' && !this.body.description) return true
      if (
        item.type === 'termsOfUse' &&
        (!this.body.temperature ||
          !this.body.exposedWorkers ||
          !this.body.exposureTime ||
          !this.body.description ||
          !this.body.frequencyUse ||
          !this.body.ventilationIdentified ||
          !this.body.quantityUsed)
      )
        return true
    },

    alertRemove() {
      this.deleteAlert = !this.deleteAlert
    },

    clear() {
      this.type = ''
      this.name = ''
      this.body = {}
      this.setFormData({})
    },

    async submitHierarchy() {
      try {
        if (this.body) this.form.body = this.body
        if (this._fieldsRequiredError(this.form)) {
          this.error = 'Todos os campos são obrigatórios'
          return
        }

        if(this.files.length > 0){
          this.form.file = this.files[0]
        }

        const data = await this.addHierarchyLevel(this.form)

        if(data._id){
          this.$emit('added', {
            name: this.form.name,
            type: this.form.type,
            _id: data._id,
            parent: this.form.parent,
          })
          this.clear()

          this.closeModal()
        } else if(data.error){
          this.error = "Erro ao fazer cadastro, tente novamente!"
          return
        }
      } catch (err) {
        console.log(err)
      }
    },

    remove() {
      this.$emit('remove', { _id: this.form._id, type: this.form.type })
      this.closeModal()
      this.alertRemove()
    },

    async update() {
      try {
        if (this.body) this.form.body = this.body

        if (this._fieldsRequiredError(this.form)) {
          this.error = 'todos os campos são obrigatórios'
          return
        }
        if (this.files.length > 0){
          this.form.file = this.files[0]
        }

        await this.updateHierarchy(this.form)

        this.$emit('updated', this.form)
        this.clear()
        this.closeModal()
      } catch (err) {
        console.log(err)
      }
    },

    closeModal() {
      this.clear()

      this.$emit('close')
    },
  },
  watch: {
    form() {
      this.error = ''
      this.files = []
      if(this.form.type === 'termsOfUse'){
        this.body = {
          temperature: '',
          ventilationIdentified: '',
          quantityUsed: '',
          frequencyUse: '',
          exposureTime: '',
          exposedWorkers: '',
          physicalStateProduct: '',
          description: '',
        }
      }
      if (this.form.body) {
        this.body = this.form.body
        if(this.body.file){
          const urlArray = this.body.file.split('/')
          this.files.push({
            name: urlArray[urlArray.length - 1]
          })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.modalVentilacao{
  display: inline-block;
  margin: 10px;
  justify-content: center;
  cursor: pointer;
  .img-caption{
    text-align: center;
    .caption{
      font-size: 13px;
      text-align: center;
      max-width: 230px;
    }
  }
}
.ventilationIdentified {
  .label {
    font-size: 18px;
    font-weight: 700;
  }
  .btn{
    width: 100%;
    min-height: 48px;
    border: 1px solid var(--border-input);
    border-radius: 4px;
    outline: none !important ;
    text-align: left;
  }

}
.hierachy-modal {
  /deep/ header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/ main {
    margin: 16px;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 4px;


      .file-uploaded {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        color: var(--blue);
        border: 2px solid var(--blue);

        padding: 6px 12px;

        border-radius: 4px;

        transition: all 0.2s ease-in-out;

        text-decoration: none;

        cursor: pointer;

        &:hover {
          background: #028bed33;
        }

        i {
          color: var(--blue);
        }
      }
    }
  }

  /deep/ footer {
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

.custom-textarea {
  height: 250px;
}
</style>
