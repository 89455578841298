<template>
  <div class='chemical-inventory mt-2'>
    <b-modal ref='alert' scrollable hide-footer title='Aviso'>
      <p>A impressao do arquivo em excel esta sendo processada,
        clique no botão abaixo para acessar a página de downloads.</p>
      <router-link :to="{ name: 'RelatoriosConsultaLegal' }">
        <base-button>
          <span class="label">Acessar</span>
        </base-button>
      </router-link>
    </b-modal>

    <b-modal
      ref='discontinuationNotice'
      id='discontinuationNotice'
      scrollable
      hide-footer
      hide-header
      title='Aviso'
      size='lg'
    >
      <section class='modal-font-family'>
        <section class='d-flex  justify-content-between'>
          <p class='discontinuation-title '>Aviso de Descontinuação da Aba</p>

          <b-icon
            style='cursor: pointer'
            @click='closeDiscontinuationNotice()'
            class='ml-1'
            font-scale='2.2'
            icon='x'
            color='#262626'
          >
          </b-icon>
        </section>

        <section class='line w-100 mb-3'>
        </section>

        <section class='discontinuation-text'>
          <p>Informamos que a aba “<span class='discontinuation-text-blue'>Inventário Químico</span>” está sendo descontinuada. </p>

          <p>
            Em seu lugar, estamos trabalhando em uma solução mais completa e integrada com outras funcionalidades,
            focada no <span class='discontinuation-text-blue'>Gerenciamento de Riscos Ocupacionais</span> (GRO).
          </p>
        </section>

        <section>
          <hr/>
        </section>

        <section class='w-100 discontinuation-text-gray'>
          <span>
            Para sua comodidade disponibilizamos todos os dados preenchidos nesta aba para download, para ter acesso,
            clique no botão abaixo:
          </span>

          <button class='btn-download p-2 text-cener mt-2' @click='toHierarchy()'>
            <span class='mr-2'>Baixar dados do Inventário Químico </span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z" fill="white"/>
            </svg>
          </button>
        </section>
      </section>
    </b-modal>

    <dialog-confirmation
      description="Você está prestes a importar um produto, ao fazer a importação os agentes químicos (caso cadastrado) serão subtituidos e as condições de uso apagada"
      :is-open="openDialogConfirmation"
      cancel="Cancelar"
      ok="Continuar"
      @confirmed="handleSubmitCopyProduct"
      @close="openDialogConfirmation = false"
    />

    <b-modal ref='show-log' size='lg' scrollable hide-footer title='Hierarquia do inventário químico'>
      <p>A hierarquia do seu inventário químico não esta completa, qual ação deseja fazer?</p>

      <div class="d-flex">
        <div class="w-50 px-1 py-1">
          <base-button
            @click="close()"
            danger
            block
          >
            Cancelar
          </base-button>
        </div>

        <div class="w-50 px-1 py-1">
          <base-button
            @click="hierarchyToBeCompleted()"
            sucess
            block
          >
            Completar hierarquia
          </base-button>
        </div>

        <div class="w-50 px-1 py-1">
          <base-button
            @click="generateIncompleteHierarchyReport()"
            sucess
            block
          >
            Gerar relatório
          </base-button>
        </div>
      </div>
    </b-modal>

    <modal-copy-product
      :isOpen='modalCopyIsOpen'
      :productId='product'
      @close='modalCopyIsOpen = false'
      @add='add'
      @update='(payload) => update(payload)'
      @edit='edit'
      @search='(payload) => openModalOptions(payload)'
    />

    <!--Modal Cadastrar, Atualizar ou Excluir item da Hierarquia-->
    <modal-hierarchy
      :isOpen='modalIsOpen'
      @added='inserted'
      @updated='updated'
      @remove='remove'
      @close='modalIsOpen = false'
    />

    <!--Modal Selecionar opções da Hierarquia-->
    <modal-options
      v-if="modalOptionsIsOpen"
      :isOpen='modalOptionsIsOpen'
      @close='modalOptionsIsOpen = false'
      @selected='selectHierarchy'
    />

    <modal-options
      v-if="modalOptionsIsOpenImport"
      :isOpen='modalOptionsIsOpenImport'
      @close='modalOptionsIsOpenImport = false'
      @selected='selectHierarchyImport'
    />

    <section class='discontinuation-text my-3' >
      <p>Informamos que a aba “<span class='discontinuation-text-blue'>Inventário Químico</span>” está sendo descontinuada. </p>

      <p>
        Em seu lugar, estamos trabalhando em uma solução mais completa e integrada com outras funcionalidades,
        focada no <span class='discontinuation-text-blue'>Gerenciamento de Riscos Ocupacionais</span> (GRO).
      </p>
    </section>
    <div class="mb-2 d-flex justify-content-between">
      <p v-if='false' class="pulse">Funcionalidade em fase de testes</p>

      <div class='w-25 text-right'>
        <button class='btn-download p-2' @click='toHierarchy()'>
          <span class='mr-2'>Baixar dados do Inventário Químico</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z" fill="white"/>
          </svg>

        </button>
      </div>
    </div>

    <div v-if='false' class="d-flex justify-content-between align-content-start">
      <div class='btn-radio'>
        <b-form-radio-group
          stacked :key="index"
          @change='reset()'
          v-for="(item, index) in inventoryOptions"
          v-model="typeInventory">
          <b-form-radio class='form-radio' :value="item.key">{{item.value}}</b-form-radio>
        </b-form-radio-group>
      </div>

      <div class="d-flex justify-content-between align-content-start" style='margin-bottom:-30px'>
        <a href='https://youtu.be/wKKvyjX_CGE' target='_blank' class="link mx-2">
          <base-button
            pulse>
            <span >Aprenda a utilizar a plataforma <br/> assistindo ao vídeo, clique aqui!</span>
          </base-button>
        </a>

        <div>
          <base-button @click="modalOpen = true">
            <span >Encontrou algum bug ou algum erro? <br/>Nos reporte clicando no botão!</span>
          </base-button>
          <Feedback v-if='modalOpen' :isOpen='modalOpen' @close='modalOpen = false' />
        </div>
      </div>
    </div>

    <div
      v-if='false'
      class='header'
      :class="`${typeInventory.includes('Complete') ? 'complete': 'simplified'}`"
    >
      <component
        class='hierarchies'
        ref='hierarchies'
        :is='typeInventory'
        :companies='companies'
        :branches='branches'
        :departments='departments'
        :roles='roles'
        :products='products'
        :termsOfUse='conditionOfUse'
        :importedProduct='importedProduct && !conditionOfUse'
        @get-options='(payload) => getOptions(payload)'
        @get-children='(payload) => getChildren(payload)'
        @set-company='setCompany'
        @set-branch='setBranch'
        @set-department='setDepartment'
        @set-product='(payload) => setProduct(payload)'
        @set-role='setRole'
        @set-conditionOfUse='setConditionOfUse'
        @add='add'
        @update='(payload) => update(payload)'
        @edit='edit'
        @search='(payload) => openModalOptions(payload)'
      />

      <div class='copy-product' v-if="role" :class="`${typeInventory.includes('Complete') ? 'complete': 'simplified'}`">
       <div class='d-flex'>
          <base-autocomplete
            _value='name'
            label='Importar produto:'
            class='input-item width-input'
            ref='product'
            placeholder='Nome do produto'
            type='product'
            :items='productsByCopy'
            :parent='role'
            v-model='copyProductId'
            @update='(payload) => update(payload)'
            @search="openModalOptionsImport('products')"
          />

         <base-button
           block
           class='button-pesquisar'
           @click="openModalOptionsImport('products')">
           Clique aqui para pesquisar
         </base-button>
       </div>
        <base-button
          block
          class='button width-input'
          @click='verifyRegisteredProductToImport()'
          :disabled='!copyProductId'
        >
          Importar
        </base-button>
      </div>
    </div>

    <div  class='summary-area' v-if='false'>
      <div class='btn-actions-summary'>
        <b-spinner v-if="checkingFullHierarchy" class='loading' variant="primary" label="Spinning"></b-spinner>

        <base-button @click="generateReport()" class='copy-button'>
          <i class='fas fa-print' /> Gerar relatório
        </base-button>
      </div>
      <Summary  v-if='product' :productId='product' />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Feedback from '@/layouts/components/Feedback'
import ModalHierarchy from '../components/ChemicalInventory/Hierarchy/Modal'
import Summary from '../components/ChemicalInventory/Summary'
import ModalOptions from '../components/ChemicalInventory/Hierarchy/ModalOptions'
import SelectCompleteInventory from '../components/ChemicalInventory/Selects/Complete'
import SelectSimplifiedInventory from '../components/ChemicalInventory/Selects/Simplified'
import ModalCopyProduct from '../components/ChemicalInventory/Hierarchy/ModalCopyProduct'
import DialogConfirmation from '@/components/DialogConfirmation.vue'
const parentLevel = {
  branches: 'company',
  departments: 'branch',
  roles: 'department',
  products: 'role',
  termsOfUse: 'product'
}
const parentLevelList = {
  company: 'companies',
  branch: 'branches',
  department: 'departments',
  role: 'roles',
  product: 'products',
}

export default {
  name: 'Hierarchy',
  components: {
    Feedback,
    ModalHierarchy,
    Summary,
    ModalOptions,
    SelectCompleteInventory,
    SelectSimplifiedInventory,
    ModalCopyProduct,
    DialogConfirmation
  },
  data() {
    return {
      typeInventory: 'SelectCompleteInventory',
      company: '',
      branch: '',
      department: '',
      role: '',
      product: '',
      conditionOfUse: '',
      openDialogConfirmation: false,
      checkingFullHierarchy: false,
      modalConfirmGenerateReportIncompleteHierarchy: false,
      productImportedSuccessfully: false,
      importedProduct: false,
      copyProductId: '',
      productsByCopy: [],
      modalIsOpen: false,
      modalOpen: false,
      modalOptionsIsOpenImport: false,
      modalOptionsIsOpen: false,
      chemicalInventoryDataToGenerateReport: {
        company: '',
        branch: '',
        department: '',
        role: '',
        conditionOfUse: '',
        product: ''
      },
      dados: {
        name: '',
        type: 'termsOfUse',
        parent: ''
      },
      inventoryOptions: [
        { key: 'SelectSimplifiedInventory', value: 'Simplificado' },
        { key: 'SelectCompleteInventory', value: 'Completo' }
      ],
      modalCopyIsOpen: false,
      tagHtml: '',
      files: [],
    }
  },
  async created() {
    const breadcrumb = {
      page: 'Inventário químico',
      subPage: {
        name: 'Inventário químico',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
    /*await this.getOptions({ type: 'companies' })
    await this.getOptions({ type: 'departments', parent: 'false' })*/
  },
  async mounted() {
    this.shawDiscontinuationNotice()
    /*if(this.user && this.user.role !== 'admin') {
      const log = {
        action: 'ACESSOU_PAGINA_INVENTARIO_QUIMICO'
      }
      await this.createUserActionLogs(log)
    }

    const typeInventory = localStorage.getItem("typeInventory");
    if(typeInventory){
      this.typeInventory = typeInventory
    }
    const query = this.typeInventory === 'SelectCompleteInventory' ? { type: 'companies' } : { type: 'departments', parent: 'false' }
    await this.getOptions(query)
    await this.hierarchyAutocomplete()
    await this.handlGetEventActive();*/
  },
  computed: {
    ...mapGetters('hierarchy', {
      companies: 'companies',
      branches: 'branches',
      departments: 'departments',
      roles: 'roles',
      products: 'products',
      termsOfUse: 'termsOfUse',
      form: 'form',
      gethierarchyToBeCompleted: 'gethierarchyToBeCompleted'
    }),
    generateReportLink() {
      return `${process.env.VUE_APP_BASE_URL}/hierarchies/export/chemicalInventory/${this.typeInventory}/${this.company || 'not_selected'}/${this.branch || 'not_selected'}/${this.department}/${this.role}/${this.conditionOfUse}/${this.product}`
    },
    user() {
      return this.$store.getters['user/current']
    },
    summary() {
      return this.$store.getters['chemicalInventory/summary']
    },
  },
  methods: {
    ...mapActions('hierarchy', {
      getOptions: 'getOptions',
      getChildren: 'getChildren',
      getParents: 'getParents',
      removeItem: 'removeItem',
      loadItem: 'loadItem',
      setFormData: 'setFormData',
      handleCopyProduct: 'handleCopyProduct',
      generateChemicalInventoryReport: 'generateChemicalInventoryReport',
      checkHierarchy: 'checkHierarchy'
    }),
    ...mapActions('chemicalInventory', {
    }),
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive',
    }),
    shawDiscontinuationNotice(){
      this.$bvModal.show('discontinuationNotice')
    },
    closeDiscontinuationNotice(){
      this.$bvModal.hide('discontinuationNotice')
    },
    async toHierarchy() {
      window.open(`/inventario-quimico/hierarquia/${this.user.id}`, '_blank');
    },
    async processReportQueue(){
      this.checkingFullHierarchy = true;
      const payload = {
        user: this.user.id,
        type: "inventory",
        job: 'chemicalInventoryReport',
        format: 'docx',
        parameters: {
          type: this.typeInventory,
          company: this.company || `not_selected`,
          branch: this.branch || `not_selected`,
          department: this.department,
          role: this.role,
          conditionOfUse: this.conditionOfUse,
          product: this.product
        }
      }
      const { data, status } = await this.handleCreateReportDownloads(payload)
      this.checkingFullHierarchy = false;
      let message = data.message
      let variant = 'danger'
      if(status === 201){
        message = data.message;
        variant = 'success';
        this.$refs['alert'].show()
        return
      }
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });
    },
    async generateReport() {
      const messageErrors = this.validateGenerateReport()
      if (messageErrors) {
        this.$bvToast.toast(messageErrors, {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        return
      }

      this.checkingFullHierarchy = true;

      const payload = {
        type: this.typeInventory,
        hierarchyId: this.typeInventory === "SelectCompleteInventory" ? this.company : this.department
      }
      const {complete} = await this.checkHierarchy(payload)
      this.checkingFullHierarchy = false;
      if(complete){
        await this.processReportQueue()
        //window.open(this.generateReportLink, '_blank');
        return
      }
      this.show()
    },

    async generateIncompleteHierarchyReport() {
      this.close()
      await this.processReportQueue()
      //window.open(this.generateReportLink, '_blank');
    },

    show() {
      this.$refs['show-log'].show()
    },

    close() {
      this.$refs['show-log'].hide()
    },

    hierarchyToBeCompleted(){
      const resetHierarchy = this.typeInventory === "SelectCompleteInventory" ? { type: "company" } : { type: "department" }
      this.selectHierarchy(resetHierarchy)

      setTimeout(() => {
        const { hierarchy } = this.gethierarchyToBeCompleted
        hierarchy.forEach((x) => {
          setTimeout(() => {
          if(x.type === "termsOfUse"){
            this.conditionOfUse = x._id
          } else {
            this.selectHierarchy(x)
          }
          }, 100)
        })

        this.close()
      }, 1000)
    },

    async hierarchyAutocomplete(){
      let company = localStorage.getItem("company");
      let branch = localStorage.getItem("branch");
      let department = localStorage.getItem("department");
      let role = localStorage.getItem("role");
      let product = localStorage.getItem("product");
      let conditionOfUse = localStorage.getItem("conditionOfUse");
      const query = this.typeInventory === 'SelectCompleteInventory' ? { type: 'companies' } : { type: 'departments', parent: 'false' }

      if(this.typeInventory === 'SelectCompleteInventory'){
        if(company !== 'undefined' && company) {
          company = JSON.parse(company)
          this.company = company._id

          const getOptionsCompany = await this.getOptions(query)
          if(getOptionsCompany && getOptionsCompany.length > 0 && getOptionsCompany.find((x) => x._id === this.company)) {
            this.$refs['hierarchies'].$refs[company.type].setSearch(company)

            if (branch !== 'undefined' && role) {
              branch = JSON.parse(branch)
              this.branch = branch._id

              const getChildrenBranch = await this.getChildren([company.type, this.company ])
              if(getChildrenBranch && getChildrenBranch.length > 0) {
                this.$refs['hierarchies'].$refs[branch.type].setSearch(branch)

                if (department !== 'undefined' && department) {
                  department = JSON.parse(department)
                  this.department = department._id

                  const getChildrenDepartment = await this.getChildren([branch.type, this.branch ])
                  if(getChildrenDepartment && getChildrenDepartment.length > 0) {
                    this.$refs['hierarchies'].$refs[department.type].setSearch(department)

                    if (role !== 'undefined' && role) {
                      role = JSON.parse(role)
                      this.role = role._id

                      const getChildrenRole = await this.getChildren([department.type, this.department])
                      if(getChildrenRole && getChildrenRole.length > 0) {
                        this.$refs['hierarchies'].$refs[role.type].setSearch(role)

                        if (product !== 'undefined' && product) {
                          product = JSON.parse(product)
                          this.product = product._id

                          const getChildrenProduct = await this.getChildren([role.type, this.role])
                          if(getChildrenProduct && getChildrenProduct.length > 0) {
                            this.$refs['hierarchies'].$refs[product.type].setSearch(product)

                            if (conditionOfUse !== 'undefined' && conditionOfUse) {
                              this.conditionOfUse = conditionOfUse
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {

        if (department !== 'undefined' && department) {
          department = JSON.parse(department)
          this.department = department._id
          const getOptionsDepartment = await this.getOptions(query)

          if(getOptionsDepartment && getOptionsDepartment.length > 0 && getOptionsDepartment.find((x) => x._id === this.department)) {
            this.$refs['hierarchies'].$refs[department.type].setSearch(department)
            if (role !== 'undefined' && role) {
              role = JSON.parse(role)
              this.role = role._id

              const getChildrenRole = await this.getChildren([department.type, this.department])

              if(getChildrenRole && getChildrenRole.length > 0) {
                this.$refs['hierarchies'].$refs[role.type].setSearch(role)

                if (product !== 'undefined' && product) {
                  product = JSON.parse(product)
                  this.product = product._id

                  const getChildrenProduct = await this.getChildren([role.type, this.role])
                  if(getChildrenProduct && getChildrenProduct.length > 0) {
                    this.$refs['hierarchies'].$refs[product.type].setSearch(product)

                    if (conditionOfUse !== 'undefined' && conditionOfUse) {
                      this.conditionOfUse = conditionOfUse
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

    async reset(){
      this.role = '';
      this.product = '';
      this.company = '';
      this.department = '';
      this.conditionOfUse = '';
      this.branch = '';
      this.importedProduct = false;

      localStorage.setItem("typeInventory", this.typeInventory);
      localStorage.setItem("company", undefined);
      localStorage.setItem("branch", undefined);
      localStorage.setItem("department", undefined);
      localStorage.setItem("role", undefined);
      localStorage.setItem("product", undefined);
      localStorage.setItem("conditionOfUse", undefined);
      const query = this.typeInventory === 'SelectCompleteInventory' ? { type: 'companies' } : { type: 'departments', parent: 'false' }
      await this.getOptions(query)
    },


    setCompany(company){
      this.company = company
      if(company){
        const companyObject = this.companies.find((x) => x._id === company);
        this.save(companyObject)
      } else {
        localStorage.setItem("company", undefined);
        localStorage.setItem("branch", undefined);
        localStorage.setItem("department", undefined);
        localStorage.setItem("role", undefined);
        localStorage.setItem("product", undefined);
        localStorage.setItem("conditionOfUse", undefined)
      }
    },

    setBranch(branch){
      this.branch = branch
      if(branch){
        const branchObject = this.branches.find((x) => x._id === branch);
        this.save(branchObject)
      } else {
        localStorage.setItem("branch", undefined);
        localStorage.setItem("department", undefined);
        localStorage.setItem("role", undefined);
        localStorage.setItem("product", undefined);
        localStorage.setItem("conditionOfUse", undefined)
      }
    },
    setDepartment(department){
      this.department = department
      if(department){
        const departmentObject = this.departments.find((x) => x._id === department);
        this.save(departmentObject)
      } else {
        localStorage.setItem("department", undefined);
        localStorage.setItem("role", undefined);
        localStorage.setItem("product", undefined);
        localStorage.setItem("conditionOfUse", undefined)
      }
    },
    setRole(role){
      this.role = role
      if(role){
        const roleObject = this.roles.find((x) => x._id === role);
        this.save(roleObject)
      } else {
        localStorage.setItem("role", undefined);
        localStorage.setItem("product", undefined);
        localStorage.setItem("conditionOfUse", undefined)
      }
    },

    async setProduct(product) {
      this.product = product;
      if(this.product) {
        const productObject = this.products.find((x) => x._id === this.product);
        this.save(productObject)
        await this.$store.dispatch('chemicalInventory/load', this.product)
      } else {
        localStorage.setItem("product", undefined);
        localStorage.setItem("conditionOfUse", undefined);
      }
    },

    async setConditionOfUse(payload) {
      this.conditionOfUse = '';
      if(payload){
        const type = 'termsOfUse'
        const data = await this.getOptions({ type, parent: this[parentLevel[type]] })
        const conditionOfUse = data.length > 0 ? data[0] : payload
        this.add(conditionOfUse)
      }
    },

    save(item){
      if(item) {
        const hierarchyItem = this[parentLevelList[item.type]] && this[parentLevelList[item.type]].length > 0 ? this[parentLevelList[item.type]].find((x) => x._id === item._id) : null
        localStorage.setItem(item.type, JSON.stringify(hierarchyItem))
      }
    },

    add(item) {
      if (item.parent === undefined) {
        delete item.parent
      }
      this.setFormData(item)
      this.modalIsOpen = true
    },

    remove(item) {
      this.removeItem(item._id)
      this[item.type] = ''
      this.$refs['hierarchies'].$refs[item.type].setSearch(item)
      localStorage.setItem(item.type, undefined);
    },

    validateGenerateReport() {
      let messageErrors = null
      if (!this.company && this.typeInventory === 'SelectCompleteInventory') {
        messageErrors = 'É obrigatório cadastrar a empresa'
      } else if (!this.branch && this.typeInventory === 'SelectCompleteInventory') {
        messageErrors = 'É obrigatório cadastrar a unidade'
      } else if (!this.department) {
        messageErrors = 'É obrigatório cadastrar a setor'
      } else if (!this.role) {
        messageErrors = 'É obrigatório cadastrar o cargo'
      } else if (!this.product) {
        messageErrors = 'É obrigatório cadastrar o produto'
      } else if (!this.conditionOfUse) {
        messageErrors = 'É obrigatório cadastrar as condições de uso'
      } else if (!this.summary || this.summary.length < 1) {
        messageErrors = 'Insira os agentes químicos'
      } else if (this.summary.filter((x) => !x.percentage).length > 0) {
        messageErrors = 'Todos o agentes precisa ter a % do agente no produto'
      }
      return messageErrors
    },

    inserted(item) {
      if(item.type === 'termsOfUse'){
        this.conditionOfUse = item._id;
        localStorage.setItem("conditionOfUse", this.conditionOfUse);
        return;
      }
      localStorage.setItem(item.type, JSON.stringify(item));
      this.$refs['hierarchies'].$refs[item.type].setSearch(item)
    },

    async update(type) {
      await this.getChildren([type, this[type]])
    },

    edit(item) {
      this.loadItem(item)
      this.$nextTick()
      this.modalIsOpen = true
    },

    async openModalOptions(type) {
      this.setFormData({
        type: type
      })
      if (this[parentLevel[type]]) {
        await this.getOptions({ type, parent: this[parentLevel[type]] })
      }
      this.modalOptionsIsOpen = true
    },

    selectHierarchy(item) {
      this.$refs['hierarchies'].$refs[item.type].setSearch(item)
      this.modalOptionsIsOpen = false
      this.save(item)
    },

    async openModalOptionsImport(type) {
      this.setFormData({
        type: type
      })
      await this.getOptions({ type, parent: false })
      this.modalOptionsIsOpenImport = true
    },

    selectHierarchyImport(item) {
      this.$refs['product'].setSearch(item)
      this.modalOptionsIsOpenImport = false
    },

    updated(item) {
      if(item.type === 'termsOfUse'){
        this.conditionOfUse = item._id;
        localStorage.setItem("conditionOfUse", this.conditionOfUse);
        return;
      }
      localStorage.setItem(item.type, JSON.stringify(item));
      this.$refs['hierarchies'].$refs[item.type].setSearch(item)
    },

    async verifyRegisteredProductToImport(){
      if(this.product){
        this.openDialogConfirmation = true;
        return
      }
      await this.handleSubmitCopyProduct();
    },

    async handleSubmitCopyProduct() {
      const response = await this.handleCopyProduct({
        productCopyId: this.copyProductId,
        rolePasteId: this.role
      })
      let message = response.message
      let variant = 'success'

      if (response.errors) {
        message = response.error
        variant = 'danger'
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        return
      }

      this.copyProductId = ''
      await this.getOptions({ type: 'products', parent: this.role })
      const fillInTheProductFieldWhenImporting = this.products.find((x) => x._id === response.data.productId)
      this.$emit('fillInTheProductFieldWhenImporting', fillInTheProductFieldWhenImporting)
      this.importedProduct = true
      //this.dados.parent = response.data.roleId

      this.$bvToast.toast('A importação do produto feita com sucesso', {
        title: 'Atenção',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.$refs['hierarchies'].$refs[fillInTheProductFieldWhenImporting.type].setSearch(fillInTheProductFieldWhenImporting)
      this.openDialogConfirmation = false;
      this.conditionOfUse = '';
    }
  },
  watch: {}
}
</script>

<style lang='scss'>
.legends {
  display: flex;
  gap: 16px;

  margin-top: 8px;

  span:nth-child(1) {
    font-weight: 700;
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 18px;
  }
}
.btn-radio{
  width: 150px;
  .form-radio{
    margin-bottom: 10px;
    cursor: pointer!important;
  }
}
.pulse {
  width: 250px;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  background-color: #ffa500;
  box-shadow: 0 0 0 0 #ffa500;
  color: var(--white);
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  &:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
  }
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
.link{
  text-decoration: none!important;
}
.button-pesquisar{
  height: 48px;
  margin-top: 45px;
  width: 240px!important
}
.loading{
  margin-left: 10px;
  width: 2.5rem;
  height: 2.5rem;
}
.width-input {
  width: 280px !important;
}

.input-import-product {
  width: 40px;
}

.btn-actions-summary {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.chemical-inventory {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: grid;
    gap: 16px;

    &.complete {
      grid-template-columns: repeat(6, 1fr);
    }

    &.simplified {
      grid-template-columns: repeat(6, 1fr);
    }

    .type-inventory {
      grid-row: 1;
    }

    .hierarchies {
      grid-row: 2;
      grid-column: 1/-1;
    }

    .copy-product {
      grid-row: 3;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;
      //padding-right: 60px;
      &.complete {
        grid-column: 1;
      }

      &.simplified {
        grid-column: 1;
        padding-left: 595px;
      }
    }
  }

  .summary-area {
    margin-top: 16px;

    .copy-button {
      margin-left: 10px;
      //margin: auto auto auto auto;
    }
  }
}

.hierarchy-selects {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.hierarchy-selects > * {
  margin-right: 15px;
}

.modal-input-item {
  margin: 16px 0px;
}

@media only screen and (min-width: 1619px) {
  .chemical-inventory {
    .header {
      .copy-product {
        &.complete {
          padding-left: 1190px;
        }
      }
    }
  }
}
.btn-download {
  width: 100%;
  border-radius: 6.25px;
  background: var(--navy-blue);
  border: none;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.btn-download {
  width: 100%;
  border-radius: 6.25px;
  background: var(--navy-blue);
  border: none;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.btn-download:hover {
  background: transparent;
  border: 1.5px solid var(--navy-blue);
  color: var(--navy-blue);
  svg {
    path {
      fill: var(--navy-blue)!important;
    }
  }
}
.discontinuation-title{
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: var(--navy-blue);
}

.discontinuation-text{
  font-size: 16px;
  font-weight: 700;
  color: #262626;
}
.discontinuation-text-gray{
  font-size: 16px;
  font-weight: 400;
  color: #808080;
}
.discontinuation-text-blue{
  font-size: 16px;
  font-weight: 700;
  color: #04A8F3;
}
.line{
  background: #D9D9D9;
  height: 1px;
}
</style>
