export default {
  getExist(key, object) {
    if (!object) return ''
    switch (key) {
      case 'acgih':
        if (object.synonyms.length < 1 || !object.agent)
          return 'fa-history'
        return object.acgih ? 'fa-check' : 'fa-times'
      case 'nr15':
        if (object.attachment1 ||
          object.attachment2 ||
          object.attachment3 ||
          object.attachment8 ||
          object.attachment14 ||
          object.attachment11 ||
          object.attachment12 ||
          object.attachment13
        )
          return 'fa-check'
        return object.nr15Unclassified
          ? 'fa-times'
          : 'fa-history'
      case 'linach':
        if (object.synonyms.length < 1 || !object.agent)
          return 'fa-history'
        return object.linach ? 'fa-check' : 'fa-times'
      case 'esocial':
        if (object.table24)
          return 'fa-check'
        return object.table24 ? 'fa-times' : 'fa-history'
      case 'decree3048':
        if (object.decree3048)
          return 'fa-check'
        return object.decreeUnclassified ? 'fa-times' : 'fa-history'
      default:
        return undefined
    }
  },
  getValue(key, object) {
    if (!object) return ''
    switch (key) {
      case 'searchId':
        return object.name || object.searchId || object.searchMatched
      default:
        return undefined
    }
  },
  tableFields: [
    {
      key: 'selectedToDelete',
      label: 'Selecionar'
    },
    {
      key: 'searchId',
      label: 'Agente'
    },
    {
      key: 'percentage',
      label: '% do agente no produto'
    },
    {
      key: 'acgih',
      label: 'acgih'
    },
    {
      key: 'nr15',
      label: 'nr15'
    },
    {
      key: 'linach',
      label: 'linach'
    },
    {
      key: 'esocial',
      label: 'esocial'
    },
    {
      key: 'decree3048',
      label: 'decree3048'
    },
    {
      key: 'actions',
      label: ''
    }
  ]
}
