<template>
  <base-modal class="modal-copy-product" :isOpened="isOpen" @close="closeModal">
    <template v-slot:header>
      <h3>Selecionar o setor destino</h3>
    </template>
    <template v-slot:content>
      <base-autocomplete
        _value="name"
        class="input-item"
        label="Nome do setor"
        placeholder="Digite ou selecione uma opção"
        ref="department"
        type="department"
        :items="departments"
        v-model="v$.department.$model"
        @add="(payload) => $emit('add', payload)"
        @change="$emit('update', 'department')"
        @edit="(payload) => $emit('edit', payload)"
        @search="$emit('search', 'departments')"
      />
    </template>
    <template v-slot:footer>
      <base-button 
        @click="closeModal" 
        danger 
        block
      > Cancelar
      </base-button>
      <base-button
        @click="handleSubmitCopy"
        :disabled="v$.$invalid"
        success 
        block
      > Copiar
      </base-button>
    </template>
  </base-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalCopyProduct',
  props: {
    isOpen: { type: Boolean, default: false },
    productId: { type: String, default: '' },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data: () => ({
    department: '',
  }),
  validations: {
    department: { required },
  },
  computed: {
    ...mapGetters('hierarchy', {
      departments: 'departments',
    }),
  },
  methods: {
    ...mapActions('hierarchy', {
      getOptions: 'getOptions',
      handleCopyProductByDepartment: 'handleCopyProductByDepartment'
    }),
    async handleSubmitCopy() {
      const response = await this.handleCopyProductByDepartment({
        productCopyId: this.productId,
        departmentPasteId: this.department
      })

      let message = '';
      let variant = '';

      if(response.message){
        message = response.message;
        variant = 'success';
      } if(response.errors){
        message = response.error;
        variant = 'danger';
      }

      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });

      if(!response.errors){
        this.$emit('close')
      }
    },
    closeModal() {
      this.$emit('close')
    },
  },
  watch: {
    async isOpen(){
      if(this.isOpen === true) {
        await this.getOptions({ type: 'departments' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-copy-product {
  /deep/ header {
    padding: 0.5rem;
    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}
</style>